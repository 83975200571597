import(/* webpackMode: "eager", webpackExports: ["InternalAIProvider"] */ "/vercel/path0/node_modules/ai/rsc/dist/rsc-shared.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/actionsClient.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/answer-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CopilotLecture"] */ "/vercel/path0/src/components/copilot-lecture.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CopilotTeach"] */ "/vercel/path0/src/components/copilot-teach.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Copilot"] */ "/vercel/path0/src/components/copilot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorCard"] */ "/vercel/path0/src/components/error-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/search-related.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchResults"] */ "/vercel/path0/src/components/search-results.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchSection"] */ "/vercel/path0/src/components/search-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchSkeleton"] */ "/vercel/path0/src/components/search-skeleton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Section"] */ "/vercel/path0/src/components/section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoSearchSection"] */ "/vercel/path0/src/components/video-search-section.tsx");
