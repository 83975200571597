import { useState } from "react";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { redirect } from "next/navigation";
import { useRouter } from "next/navigation";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form";
import { useToast } from "@/components/ui/use-toast";
import { signIn, useSession } from 'next-auth/react'
export const description =
  "A login form with email and password. There's an option to login with Google and a link to sign up if you don't have an account.";

const loginFormSchema = z.object({
  email: z.string().min(1, "Email is required").email("Invalid email"),
  password: z
    .string()
    .min(1, "Password is required")
    .min(8, "Password must have than 8 characters"),
});

const registerFormSchema = z
  .object({
    email: z.string().min(1, "Email is required").email("Invalid email"),
    password: z
      .string()
      .min(1, "Password is required")
      .min(8, "Password must have than 8 characters"),
    confirmPassword: z.string().min(1, "Password confirmation is required"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Password do not match",
  });

export function AuthForm() {
  const [displayRegisterForm, setDisplayRegisterForm] = useState(false);
  const router = useRouter();
  const { toast } = useToast();

  const loginForm = useForm<z.infer<typeof loginFormSchema>>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmitLogin = async (values: z.infer<typeof loginFormSchema>) => {
    console.log(values);
    const signInData = await signIn("credentials", {
      email: values.email,
      password: values.password,
      redirect: false,
    });
    if (signInData?.error) {
      toast({
        title: "Error",
        description: " Oops! Something went wrong",
        variant: "destructive",
      });
      console.log("error occcured login");
      console.log(signInData.error);
    } else {
      router.push("/");
    }
    console.log(signInData);
  };

  const registerForm = useForm<z.infer<typeof registerFormSchema>>({
    resolver: zodResolver(registerFormSchema),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmitRegister = async (
    values: z.infer<typeof registerFormSchema>
  ) => {
    console.log(values);
    const response = await fetch("/api/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: values.email,
        password: values.password,
      }),
    });
    
    console.log(response.status);
    if (response.ok) {
      setDisplayRegisterForm(false);
      router.push("/");
    } else {
      toast({
        title: "Error",
        description: " Oops! Something went wrong",
        variant: "destructive",
      });
      console.log("registration failed");
    }
  };

  // const handleGoogleLogin = async () => {
  //   try {
  //     const res = await fetch("/api/auth/login", {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     console.log('Show me google login =>', res)

  //   redirect('/nick');
  //   } catch (error) {
  //     setErrorMessage("An error occurred. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const toggleToRegister = () => {
    setDisplayRegisterForm(!(displayRegisterForm));
  };
  return (
    <div>
      {!displayRegisterForm && (
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Welcome back</CardTitle>
            <CardDescription>Sign in to your account</CardDescription>
          </CardHeader>
          <CardContent>
            <Form {...loginForm}>
              <form onSubmit={loginForm.handleSubmit(onSubmitLogin)}>
                <div className="grid gap-4">
                  <FormField
                    control={loginForm.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input placeholder="mail@example.com" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={loginForm.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input
                            type="password"
                            placeholder="Enter your password"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button className="w-full mt-6" type="submit">
                    Sign in
                  </Button>
                </div>
              </form>
            </Form>
            <div className="grid gap-4">
              <Button
                variant="outline"
                className="w-full mt-2"
                onClick={() => signIn('google')}
              >
                Login with Google
              </Button>
              <div className="mt-4 text-center text-sm">
                Don&apos;t have an account?{" "}
                <Link href="#" className="underline" onClick={toggleToRegister}>
                  Sign up
                </Link>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {/* Register Form */}
      {displayRegisterForm && (
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Welcome To Fetchit</CardTitle>
            <CardDescription>Register for an account</CardDescription>
          </CardHeader>
          <CardContent>
            <Form {...registerForm}>
              <form onSubmit={registerForm.handleSubmit(onSubmitRegister)}>
                <div className="grid gap-4">
                  <FormField
                    control={registerForm.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input placeholder="mail@example.com" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={registerForm.control}
                    name="password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input
                            type="password"
                            placeholder="Enter your password"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={registerForm.control}
                    name="confirmPassword"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Re-Enter your password</FormLabel>
                        <FormControl>
                          <Input
                            placeholder="Re-Enter your password"
                            type="password"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button className="w-full mt-6" type="submit">
                    Sign Up
                  </Button>
                </div>
              </form>
            </Form>
            <div className="grid gap-4">
              <Button
                variant="outline"
                className="w-full mt-2"
                onClick={() => {}}
              >
                Login with Google
              </Button>
              <div className="mt-4 text-center text-sm">
                Already have an account?
                {/* Don&apos;t have an account?{" "} */}
                <Link href="#" className="underline" onClick={toggleToRegister}>
                  Sign In
                </Link>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}
