'use client'

import { Card } from './ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { Section } from './section'
import { StreamableValue, useStreamableValue } from 'ai/rsc'
import { BotMessage } from './message'
import { useEffect, useState } from 'react'
import { useAppState } from "@/lib/utils/app-state"

export type AnswerSectionProps = {
  result?: StreamableValue<string>
  hasHeader?: boolean
}

export function AnswerSection({
  result,
  hasHeader = true
}: AnswerSectionProps) {
  const [data, error, pending] = useStreamableValue(result)
  const { setIsGenerating } = useAppState()

  const [content, setContent] = useState<string>('')

  useEffect(() => {
    if (!data) return
    setContent(data)
  }, [data])
  
  useEffect(() => {
    if(!pending){
      setIsGenerating(false);
    }
  }, [pending])

  return (
    <div>
      {content.length > 0 ? (
        <Card className="p-5 rounded-xl w-full mx-auto bg-white shadow-lg transition-all">
          <Section title={hasHeader ? 'Answer' : undefined}>
            <BotMessage content={content} />
          </Section>
        </Card>
      ) : (
        <div className="flex flex-col gap-2 py-2 ">
          <Skeleton className="h-6 w-48 bg-indigo-500" />
          <Skeleton className="w-full h-6 bg-indigo-500" />
        </div>
      )}
    </div>
  )
}
