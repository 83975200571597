import React, { ReactNode } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
// import { XIcon } from '@heroicons/react/solid'; // Uncomment if using an icon

interface ModalProps {
  isOpen: boolean;
  onOpenChange?: (open: boolean) => void;
  title?: string;
  description?: string;
  width?: number;
  children: ReactNode;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onOpenChange,
  title,
  description,
  width,
  children,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className={`${width? `sm:max-w-[${width}px]` : 'sm:max-w-[925px]'} w-full mx-4 bg-white rounded-lg shadow-lg`}>
        <DialogHeader className="flex justify-between items-start">
          <div>
            <DialogTitle className="text-lg font-semibold">{title}</DialogTitle>
            {description && (
              <DialogDescription className="mt-1 text-sm text-gray-600">
                {description}
              </DialogDescription>
            )}
          </div>
        </DialogHeader>
        <div className="p-4">{children}</div>
      </DialogContent>
    </Dialog>
  );
};
