"use client";

import { ClientChat } from "@/components/artifacts/chat";
import { Playground } from "@/components/artifacts/playground";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { useUIState } from "ai/rsc";
import React, { useEffect, useState } from "react";
import { AuthForm } from "@/components/AuthForm";
import { Modal } from "@/components/Modal";
import { useSession } from "next-auth/react";

export default function ActionClient() {
  const [code, setCode] = React.useState("");
  const [isCodeLoading, setIsCodeLoading] = React.useState(false);
  const [messages] = useUIState();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState(true); // New loading state
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);

  const { data: session } = useSession();

  useEffect(() => {
    if (!session) {
      console.log("login status:");
      setIsUserLoggedIn(false);
    } else {
      setIsUserLoggedIn(true);
    }
  }, [session]);

  React.useEffect(() => {
    const checkAuthStatus = async () => {
      setIsLoading(true);
      setTimeout(() => {
        console.log("login status: ", isUserLoggedIn, " ", session);
        if (!isUserLoggedIn) {
          setIsModalOpen(true);
        }
        setIsLoading(false);
      }, 100); // wait for 1 second to simulate auth check
    };

    checkAuthStatus();
  }, [isUserLoggedIn]);

  return (
    <div className="h-screen">
      <ResizablePanelGroup direction="horizontal" className="h-full">
        <ResizablePanel defaultSize={75} minSize={50}>
          <div className="overflow-auto h-full p-4">
            <ClientChat />
          </div>
        </ResizablePanel>
        <ResizableHandle />
        <ResizablePanel defaultSize={25} minSize={20}>
          <div className="overflow-auto h-full p-4">
            {isLoading ? (
              <div>Loading...</div> // Replace with a better loading indicator if needed
            ) : isUserLoggedIn ? (
              <>
                <Playground initialCode={code} isCodeLoading={isCodeLoading} />
              </>
            ) : (
              <div>
                <Playground initialCode={code} isCodeLoading={isCodeLoading} />
                <Modal
                  isOpen={isModalOpen}
                  // onOpenChange={setIsModalOpen}
                >
                  <AuthForm />
                </Modal>
              </div>
            )}
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
}
