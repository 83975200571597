import { useEffect, useState, useRef, useCallback } from 'react';
import { debounce } from 'lodash';

const MIN_CHUNK_SIZE = 5; // Minimum number of words per chunk
const MAX_CHUNK_SIZE = 25; // Maximum number of words per chunk

export function useTextToSpeech(text: string) {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioContextRef = useRef<AudioContext | null>(null);
  const sourceNodeRef = useRef<AudioBufferSourceNode | null>(null);
  const currentChunkIndexRef = useRef<number>(0);
  const chunksRef = useRef<string[]>([]);
  const bufferQueueRef = useRef<AudioBuffer[]>([]);

  const chunkText = useCallback((text: string): string[] => {
    const sentences = text.split(/(?<=\.|\?|\!)\s+/);
    const chunks: string[] = [];
    let currentChunk: string[] = [];
    
    sentences.forEach(sentence => {
      const words = sentence.split(' ');
      if (currentChunk.length + words.length <= MAX_CHUNK_SIZE) {
        currentChunk.push(sentence);
      } else {
        if (currentChunk.length >= MIN_CHUNK_SIZE) {
          chunks.push(currentChunk.join(' '));
          currentChunk = [sentence];
        } else {
          // If the current chunk is too small, we'll exceed MAX_CHUNK_SIZE
          const remainingSpace = MAX_CHUNK_SIZE - currentChunk.length;
          const firstPart = words.slice(0, remainingSpace).join(' ');
          currentChunk.push(firstPart);
          chunks.push(currentChunk.join(' '));
          currentChunk = [words.slice(remainingSpace).join(' ')];
        }
      }
    });
    
    if (currentChunk.length > 0) {
      chunks.push(currentChunk.join(' '));
    }
    
    return chunks;
  }, []);

  const synthesizeChunk = async (chunk: string): Promise<ArrayBuffer> => {
    const response = await fetch('/api/synthesize-speech', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ chunk }),
    });

    if (!response.ok) {
      throw new Error('Failed to synthesize speech');
    }

    return await response.arrayBuffer();
  };

  const playNextChunk = useCallback(async () => {
    if (currentChunkIndexRef.current >= chunksRef.current.length) {
      setIsPlaying(false);
      return;
    }

    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || (window as any).webkitAudioContext)();
    }

    if (bufferQueueRef.current.length === 0) {
      // If the buffer queue is empty, wait for the next chunk to be synthesized
      const chunk = chunksRef.current[currentChunkIndexRef.current];
      const audioData = await synthesizeChunk(chunk);
      const audioBuffer = await audioContextRef.current.decodeAudioData(audioData);
      bufferQueueRef.current.push(audioBuffer);
    }

    const audioBuffer = bufferQueueRef.current.shift()!;
    sourceNodeRef.current = audioContextRef.current.createBufferSource();
    sourceNodeRef.current.buffer = audioBuffer;
    sourceNodeRef.current.connect(audioContextRef.current.destination);
    sourceNodeRef.current.onended = () => {
      currentChunkIndexRef.current++;
      playNextChunk();
    };
    sourceNodeRef.current.start();

    // Preload the next chunk
    if (currentChunkIndexRef.current + 1 < chunksRef.current.length) {
      const nextChunk = chunksRef.current[currentChunkIndexRef.current + 1];
      synthesizeChunk(nextChunk).then(audioData => 
        audioContextRef.current!.decodeAudioData(audioData)
      ).then(audioBuffer => {
        bufferQueueRef.current.push(audioBuffer);
      });
    }
  }, []);

  const processText = useCallback(
    debounce(async (text: string) => {
      if (!text) return;

      try {
        chunksRef.current = chunkText(text);
        currentChunkIndexRef.current = 0;
        bufferQueueRef.current = [];
        setIsPlaying(true);
        await playNextChunk();
      } catch (error) {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      }
    }, 300),
    [chunkText, playNextChunk]
  );

  const stopAudio = useCallback(() => {
    if (sourceNodeRef.current) {
      sourceNodeRef.current.stop();
      sourceNodeRef.current = null;
    }
    setIsPlaying(false);
    currentChunkIndexRef.current = chunksRef.current.length; // Prevent further playback
    bufferQueueRef.current = []; // Clear the buffer queue
  }, []);

  useEffect(() => {
    processText(text);

    return () => {
      stopAudio();
    };
  }, [text, processText, stopAudio]);

  return { isPlaying, stopAudio };
}