"use client";

import * as shadcnComponents from "@/components/ui";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import * as phosphorIcons from "@phosphor-icons/react";
import * as datefns from "date-fns";
import { useEffect, useState } from "react";
import { LiveEditor, LivePreview, LiveProvider } from "react-live";
import * as recharts from "recharts";
import { SquarePen } from "lucide-react"; // Importing lucide-react chat bubble icon

import { useRouter } from "next/navigation";
import { PreviewScreen } from "./preview-screen";
import { ChatSettings } from "../chat-settings";
import { MyAccountMenu } from "../my-account-menu";

interface PlaygroundProps {
  initialCode: string;
  isCodeLoading: boolean;
}

export function Playground({ initialCode }: PlaygroundProps) {
  const router = useRouter();
  const [code, setCode] = useState(initialCode);

  const scope = {
    ...shadcnComponents,
    ...phosphorIcons,
    ...recharts,
    ...datefns,
  };

  // This is a wrapper component that will be used by LivePreview
  const PreviewWrapper = () => {
    return <PreviewScreen code={code} />;
  };

  useEffect(() => {
    setCode(initialCode);
  }, [initialCode]);

  return (
    <LiveProvider code={code} scope={scope} noInline={true}>
      <div className="flex flex-col h-full">
        <div className="flex justify-start mb-2">
          <Button
            type="submit"
            onClick={() => window.location.reload()}
            className="flex items-center justify-center bg-gray-700 text-white font-semibold px-6 py-3 rounded-sm shadow-lg transition-transform duration-300 hover:bg-gray-800"
          >
            <SquarePen className="h-5 w-5 mr-2" />{" "}
            {/* Adds margin between icon and text */}
            <p className="flex items-center">New chat</p>
          </Button>
        </div>

        <Tabs defaultValue="preview" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="preview">Profile</TabsTrigger>
            <TabsTrigger value="code">History</TabsTrigger>
          </TabsList>

          <TabsContent value="preview">
            <ChatSettings />
          </TabsContent>
        </Tabs>

        <div className="flex items-center justify-center w-full mt-auto">
          <MyAccountMenu />
        </div>
      </div>
    </LiveProvider>
  );
}
