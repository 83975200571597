import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export function Pricing() {

  const handleCreateCheckoutSession = async () => {
    try {
      const res = await fetch('/api/stripe/checkout-session', {
        method: 'POST',
        body: JSON.stringify({
          product_id: 'prod_Qs40ETYpjxshs0',
          user: {
            email: 'test@test.com',
            name: `${'user.firstname'} ${'user.lastname' || ''}`.trim(),
          }
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!res.ok) {
        throw new Error(`Error: ${res.status}`);
      }

      if (res.ok) {
        const res_json = await res.json();
        console.log('Stripe return =>', res_json)
        window.location.replace(res_json?.session_data?.url);
      }
    } catch (error) {
      console.error('Failed to create checkout session:', error);
    }
  }

  // Checkmark Icon as an inline SVG
  const Checkmark = () => (
    <svg
      className="w-5 h-5 inline-block text-blue-500 mr-2"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 13l4 4L19 7"
      ></path>
    </svg>
  );

  return (
    <div className="space-y-8">
      {/* Header */}
      <div className="text-center space-y-3">
        <h1 className="text-5xl font-bold">
          Elevate your <span className="bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">learning</span> experience
        </h1>
        <p className="text-sm text-gray-600">Discover a new way to learning.</p>
      </div>

      {/* Pricing Plans */}
      <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2">
        {/* Free Plan */}
        <Card className="border border-gray-200 shadow-sm hover:shadow-lg transition-shadow duration-300">
          <CardHeader className="ml-6 p-6">
            <CardTitle className="text-2xl font-semibold">Free</CardTitle>
            <span className="text-sm">Try out AI-powered learning with basic features at no cost.</span>
            <CardDescription className="text-4xl mt-2 font-bold">
              <span className="text-black">$0</span><span className="text-lg font-normal">/month</span>
            </CardDescription>
          </CardHeader>
          <CardContent>
            <ul className="space-y-3 px-6 text-gray-700">
              <li>
                <Checkmark /> Questions/Answers: 5
              </li>
              <li>
                <Checkmark /> Copilot lectures: 5
              </li>
              <li>
                <Checkmark /> Copilot searches: 5
              </li>
              <li>
                <Checkmark /> File uploads: 5
              </li>
              <li>
                <Checkmark /> Unlimited follow-up questions
              </li>
              <li className="text-sm">*Login required to activate all features</li>
            </ul>
          </CardContent>
          <CardFooter className="text-center p-6">
            {/* Current Plan Button */}
            <button
              className="bg-gray-200 text-gray-600 font-semibold py-2 px-4 rounded-lg cursor-not-allowed"
              disabled
            >
              Current Plan
            </button>
          </CardFooter>
        </Card>

        {/* Pro Plan */}
        <Card className="border border-blue-200 shadow-sm bg-blue-50 hover:shadow-lg transition-shadow duration-300">
          <CardHeader className="ml-6 p-6">
            <CardTitle className="text-2xl font-semibold">Pro</CardTitle>
            <span className="text-sm">For individuals looking to amplify their learning</span>
            <CardDescription className="text-4xl mt-2 font-bold">
              <span className="text-black">$20</span><span className="text-lg font-normal">/month</span>
            </CardDescription>
          </CardHeader>
          <CardContent>
            <ul className="space-y-3 px-6 text-gray-700">
              <li>
                <Checkmark /> All Free features included
              </li>
              <li>
                <Checkmark /> Unlimited Questions/Answers
              </li>
              <li>
                <Checkmark /> Unlimited Copilot lectures
              </li>
              <li>
                <Checkmark /> Unlimited Copilot searches
              </li>
              <li>
                <Checkmark /> Unlimited file uploads
              </li>
              <li>
                <Checkmark /> Unlimited follow-up questions
              </li>
              <li>
                <Checkmark /> Advanced AI models (Quality)
              </li>
              <li>
                <Checkmark /> Early access to beta features
              </li>
            </ul>
          </CardContent>
          <CardFooter className="text-center p-6">
            {/* Pro Plan Button */}
            <button 
              className="bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-gray-800 transition"
              onClick={handleCreateCheckoutSession}
            >
              Choose Pro
            </button>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}
