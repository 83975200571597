import React from 'react'
import { ChatShare } from './chat-share'
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Code,
  PaperPlaneRight,
  Spinner,
  Paperclip,
  Dog,
  User,
} from "@phosphor-icons/react";

type UserMessageProps = {
  message: string
  chatId?: string
  showShare?: boolean
}

export const UserMessage: React.FC<UserMessageProps> = ({
  message,
  chatId,
  showShare = false
}) => {
  const enableShare = process.env.ENABLE_SHARE === 'true'
  return (
<div className="flex items-start w-full space-x-1 mt-2 min-h-10 justify-end">
  <div className="text-xl break-words bg-primary text-primary-foreground max-w-[70%] rounded-lg px-4 py-3">
    {message}
  </div>
  <Avatar className="h-8 w-8 border">
    {/* <AvatarImage src="/placeholder-user.jpg" /> */}
    <AvatarFallback>
      <User className="h-5 w-5" />
    </AvatarFallback>
  </Avatar>
</div>

  )
}
