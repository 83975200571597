"use client";

import React, { useEffect, useState } from "react";
import { PartialLecture } from "@/lib/schema/outputs";
import { Card } from "./ui/card";
import { Button } from "./ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { BotMessage } from "./message";
import {
  Sparkles,
  Image,
  TvMinimalPlay,
  ListOrdered,
  Volume2,
} from "lucide-react";
import {
  StreamableValue,
  useActions,
  useStreamableValue,
  useUIState,
} from "ai/rsc";
import type { AI } from "@/app/actions";
import { useAppState } from "@/lib/utils/app-state";
import { UserMessage } from "./user-message";
import { generateId } from "ai";
import { useTextToSpeech } from "@/lib/audio";

export type CopilotProps = {
  response?: StreamableValue<PartialLecture>;
};

export const CopilotLecture: React.FC<CopilotProps> = ({
  response,
}: CopilotProps) => {
  const [data, error, pending] = useStreamableValue<PartialLecture>(response);
  const [object, setObject] = useState<PartialLecture>();
  const [itStated, setItStarted] = useState(false);
  const [, setMessages] = useUIState<typeof AI>();
  const { submit } = useActions();
  const { isGenerating, setIsGenerating, autoScrollMode, setAutoScrollMode } =
    useAppState();
  const [currentTextToSpeech, setCurrentTextToSpeech] = useState<string>("");
  const { isPlaying, stopAudio } = useTextToSpeech(currentTextToSpeech);

  useEffect(() => {
    if (!data) return;
    setObject(data);
    setItStarted(true);
  }, [data]);

  useEffect(() => {
    if (!pending) {
      setIsGenerating(false);
    }
  }, [pending]);

  const onFormSubmit = async (
    e: React.FormEvent<HTMLFormElement> | any,
    skip?: boolean
  ) => {
    e.preventDefault();

    setIsGenerating(true);
    setAutoScrollMode(true);
    const queryString = e.target.value;
    console.log("Show me submit e value new =>", queryString);

    setMessages((currentMessages: any) => [
      ...currentMessages,
      {
        id: generateId(),
        component: <UserMessage message={queryString} />,
      },
    ]);

    const data = {
      userInput: {
        role: "user",
        content: queryString,
      },
    };
    const response = await submit(data);
    setMessages((currentMessages) => [...currentMessages, response]);
  };

  if (error) {
    return (
      <Card className="p-4 w-full flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <Sparkles className="w-4 h-4" />
          <h5 className="text-muted-foreground text-xs truncate">
            {`error: ${error}`}
          </h5>
        </div>
      </Card>
    );
  }

  const listenToLecture = (text: string) => {
    stopAudio();
    setCurrentTextToSpeech(text);
  };

  return (
    <div>
      {itStated ? (
        <Card className="p-8 rounded-2xl w-full mx-auto bg-gradient-to-r from-teal-50 to-purple-50 shadow-xl transition-all">
          <div
            className={`ml-3 ${
              pending ? "text-red-500" : "text-purple-600"
            } flex items-center`}
          >
            <span className="text-xl font-bold">🎓 Lecture Session:</span>
          </div>

          <div
            className={`mt-5 ${
              object?.subject && `mb-6 border-b-4 pb-6 border-purple-300`
            }`}
          >
            <h2 className="text-3xl font-extrabold text-teal-800 ml-4">
              <BotMessage content={object?.subject as string} />
            </h2>
            <p className="text-lg text-purple-600 ml-4 mt-2">
              {object?.topics?.join(", ")}
            </p>
          </div>

          <div className="space-y-8 mt-6">
            {object?.explanation?.map((explanation, index) => (
              <div
                key={`explanation-${index}`}
                className="bg-white p-6 rounded-xl shadow-md transition-transform transform"
              >
                <div className="mb-6">
                  <h3 className="text-lg font-semibold text-teal-700">
                    <BotMessage content={explanation?.title as string} />
                  </h3>
                  <p className="text-md text-teal-500 italic mt-2">
                    <BotMessage content={explanation?.lecture as string} />
                  </p>
                </div>
                <div className="pl-6 mt-4 space-y-5 border-l-4 border-purple-300">
                  {explanation?.breakdowns?.map((breakdown, breakdownIndex) => (
                    <div
                      key={`breakdown-${breakdownIndex}`}
                      className="text-md text-gray-800"
                    >
                      <p className="leading-relaxed flex">
                        <span className="mr-1 font-medium text-purple-600">
                          {breakdown?.step}:
                        </span>{" "}
                        {/* <span className="flex-1"> */}
                        <BotMessage
                          content={breakdown?.description as string}
                        />
                        {/* </span> */}
                      </p>
                    </div>
                  ))}
                </div>
                {explanation?.answer && (
                  <div className="mt-8">
                    <p className="text-xl font-semibold text-gray-800 flex">
                      <span className="mr-1 text-teal-600">
                        ✨ Key Insight:
                      </span>
                      <BotMessage content={explanation?.answer} />
                    </p>
                  </div>
                )}
                {explanation?.visuals && explanation?.visuals?.length > 0 && (
                  <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                    {explanation?.visuals?.map((visual, visualIndex) => (
                      <div
                        key={`visual-${visualIndex}`}
                        className="flex flex-col items-start bg-gray-100 p-4 rounded-lg shadow-sm"
                      >
                        <a
                          href={visual?.visual}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={visual?.visual}
                            alt={visual?.description}
                            className="w-1/2 rounded-md transition-transform transform hover:scale-150 duration-300"
                            onError={(e) =>
                              (e.currentTarget.src =
                                "https://files.ds106.us/wp-content/uploads/sites/4/2014/12/placeholder.png")
                            }
                          />
                          <p className="mt-4 text-gray-700 text-sm">
                            {visual?.description}
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex justify-end mt-4">
                  <button
                    type="button"
                    className={`flex items-center px-5 py-2.5 border border-gray-300 rounded-md bg-white text-gray-800 hover:bg-gray-100 transition-all transform hover:scale-105 shadow-sm ${
                      pending ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={pending}
                    onClick={() =>
                      listenToLecture(explanation?.sectionLecture || "")
                    }
                  >
                    <Volume2 size={18} className="mr-2 text-teal-600" />
                    <span className="font-medium">Listen</span>
                  </button>
                </div>
                {isPlaying && (
                  <span className="font-medium">Something Visual here</span>
                )}
              </div>
            ))}
          </div>

          {!pending && (
            <div className="flex justify-end space-x-2 mt-4">
              <Button
                type="button"
                variant="outline"
                className="flex items-center px-3 py-2"
                disabled={pending}
                value={
                  object?.imagesSearchButton === ""
                    ? "Search for images"
                    : object?.imagesSearchButton
                }
                onClick={(e) => onFormSubmit(e as any)}
              >
                <Image size={16} className="mr-1" />
                Images
              </Button>
              <Button
                type="button"
                variant="outline"
                className="flex items-center px-3 py-2"
                disabled={pending}
                value={
                  object?.videosSearchButton === ""
                    ? "Search for videos"
                    : object?.videosSearchButton
                }
                onClick={(e) => onFormSubmit(e as any)}
              >
                <TvMinimalPlay size={16} className="mr-1" />
                Videos
              </Button>
            </div>
          )}
          {object?.followUpRequests && (
            <div className="flex flex-col">
              <span className="text-lg w-full text-purple-600">
                {" "}
                You may also want to ask
              </span>
              <div className="flex items-center flex-wrap space-y-2">
                {object?.followUpRequests?.map((followup, index) => (
                  <Button
                    key={index}
                    type="button"
                    variant="outline"
                    className="flex items-center mt-2 ml-1 px-4 py-2 border-gray-300 text-gray-700 rounded hover:bg-gray-100"
                    disabled={pending}
                    value={followup?.request}
                    onClick={(e) => onFormSubmit(e as any)}
                  >
                    <ListOrdered size={16} className="mr-2" />
                    {followup?.request}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </Card>
      ) : (
        <div className="flex flex-col gap-2 py-2">
          <Skeleton className="h-6 w-48" />
          <Skeleton className="w-full h-6" />
        </div>
      )}
    </div>
  );
};
