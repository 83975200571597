"use client";
export const maxDuration = 60;

import { useEffect, useState, useRef } from "react";
import { PaperPlaneRight, Paperclip } from "@phosphor-icons/react";
import Image from "next/image";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { Button } from "@/components/ui/button";
import { useUIState, useActions } from "ai/rsc";
import { ChatMessage } from "./ChatMessage";
import { UserMessage } from "../user-message";
import { useAppState } from "@/lib/utils/app-state";
import { generateId } from "ai";
import { BotMessage } from "../message";
import { BookOpen, Lightbulb, Search, Puzzle, Mic } from "lucide-react";

export function ClientChat() {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [input, setInput] = useState("");
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [imgPreview, setImgPreview] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { submit } = useActions();
  const [messageState, setMessages] = useUIState();
  const {
    isGenerating,
    setIsGenerating,
    autoScrollMode,
    setAutoScrollMode,
    isMessagesEmpty,
    setIsMessagesEmpty,
  } = useAppState();

  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const scrollTop = scrollContainerRef.current.scrollTop;
      if (scrollTop < lastScrollTop) {
        // Trigger your function when scrolling up
        onScrollUp();
        setAutoScrollMode(false);
      }
      setLastScrollTop(scrollTop);
    }
  };

  const onScrollUp = () => {
    console.log("Scrolled up inside the div!");
    // Place your scroll-up specific function here
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastScrollTop]);

  useEffect(() => {
    let scrollInterval: NodeJS.Timeout | undefined;
    if (isGenerating && autoScrollMode) {
      // Scroll to the bottom while generating
      scrollInterval = setInterval(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    } else {
      // Scroll to the bottom when not generating
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    }
    return () => {
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };
  }, [isGenerating, autoScrollMode]);

  useEffect(() => {
    if (textareaRef.current) {
      // Adjust textarea height
      textareaRef.current.style.height = "4rem";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      if (textareaRef.current.scrollHeight > 192) {
        textareaRef.current.style.height = "12rem";
      }
    }
  }, [input]);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setImgFile(files[0]);
      setImgPreview(URL.createObjectURL(files[0]));
    }
  };

  const handleRemove = () => {
    setImgFile(null);
    setImgPreview("");
  };

  const handleSubmit = async (input_?: string) => {
    setIsMessagesEmpty(false);
    setIsGenerating(true);
    setAutoScrollMode(true);

    const messageContent = input_ || input;

    // Add user message to UI state
    setMessages((currentMessages: any) => [
      ...currentMessages,
      {
        id: generateId(),
        component: <UserMessage message={messageContent} />,
      },
    ]);

    const data = {
      userInput: {
        role: "user",
        content: messageContent,
      },
      imgFile: imgFile || null,
    };

    setInput("");
    textareaRef.current?.focus();

    const responseMessage = await submit(data);
    setMessages((currentMessages: any) => [...currentMessages, responseMessage]);

    setImgFile(null);
    setImgPreview("");
    setIsGenerating(false);
  };

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.error(err) // onNotAllowedOrFound
  );

  const ExampleInputsCard = ({
    icon,
    title,
    onCardClick,
  }: {
    icon: React.ReactNode;
    title: string;
    onCardClick: (title: string) => void;
  }) => (
    <div
      className="flex flex-col items-center justify-center p-4 w-48 h-36 rounded-lg bg-white hover:bg-gray-100 text-gray-600 border cursor-pointer"
      onClick={() => onCardClick(title)}
    >
      <div className="flex items-center justify-center w-10 h-10 mb-2 text-xl">{icon}</div>
      <div className="text-center text-sm font-normal">
        <BotMessage content={title} />
      </div>
    </div>
  );

  const ExampleInputs = () => {
    const handleCardClick = (title: string) => {
      handleSubmit(title);
    };

    return (
      <div className="flex flex-col justify-center items-center h-full -translate-y-[53px]">
        <div className="flex flex-col items-center justify-center mb-8">
          <img
            src="https://media.istockphoto.com/id/1339851357/vector/dog-icon-vector-isolated-funny-puppy-head-pictogram-on-white-background.jpg?s=612x612&w=0&k=20&c=mOqd-O-dtpKjUhR52McMA8ifM-AY8H3BmJvJtgtB_ZE="
            alt="Logo"
            className="w-16 h-16"
          />
          <span className="text-[2.45rem] font-semibold text-center">
            Discover <span className="bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">Smarter</span> Learning
          </span>
          <span className="text-sm text-muted-foreground">Unlock intelligent learning with Fetchit.</span>
        </div>
        <div className="flex space-x-4">
          <ExampleInputsCard
            icon={<Puzzle className="text-blue-500" size={24} />}
            title="Break down complex problems"
            onCardClick={() =>
              handleCardClick("Solve and breakdown second derivative of sin(2x)")
            }
          />
          <ExampleInputsCard
            icon={<BookOpen className="text-red-500" size={24} />}
            title="Create a personalized lecture"
            onCardClick={() => handleCardClick("Lecture me on the Roman Emperor")}
          />
          <ExampleInputsCard
            icon={<Search className="text-green-500" size={24} />}
            title="Search the web for answers"
            onCardClick={() =>
              handleCardClick("Find me real-world examples of carbon being used")
            }
          />
          <ExampleInputsCard
            icon={<Lightbulb className="text-yellow-500" size={24} />}
            title="Find relevant images and videos"
            onCardClick={() =>
              handleCardClick("Find me images and videos of the French Revolution")
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-full flex-col">
      <div
        ref={scrollContainerRef}
        className="flex flex-col h-full overflow-y-auto p-4"
      >
        <div className="flex-1 grid gap-4">
          {isMessagesEmpty && <ExampleInputs />}
          <ChatMessage messages={messageState} />
        </div>
        <div ref={messagesEndRef} />
      </div>

      {/* Image Preview */}
      <div className="flex flex-col">
        {imgFile && (
          <div className="flex flex-row ml-10 w-full p-1 relative">
            <Image
              className="bg-gray-100 rounded-md"
              src={imgPreview}
              alt="File Thumbnail"
              width={150}
              height={150}
              objectFit="contain"
            />
            <button
              onClick={handleRemove}
              className="absolute bg-red-600 text-white border-none rounded-full w-8 h-8 cursor-pointer text-center leading-5 text-xl"
              style={{ transform: "translate(-50%, -50%)" }}
            >
              X
            </button>
          </div>
        )}

        <div className="bg-background flex items-center gap-2 border-t px-4 py-3">
          <Button variant="ghost" size="icon" onClick={handleButtonClick}>
            <Paperclip className="h-5 w-5" />
            <span className="sr-only">Attach file</span>
          </Button>
          <Button variant="ghost" size="icon">
            <Mic className="h-5 w-5" />
            <span className="sr-only">Use Microphone</span>
          </Button>
          {/* Hidden Input for File Upload */}
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleFileChange}
          />

          <textarea
            ref={textareaRef}
            placeholder="Hi Nick, how can I help you today?"
            className="flex-1 w-full p-2 pr-12 border border-gray-300 rounded-lg resize-y overflow-y-auto focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={2}
            required
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
          <Button variant="ghost" size="icon" className="rounded-full" onClick={handleSubmit as any}>
            <PaperPlaneRight className="h-5 w-5" />
            <span className="sr-only">Send</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
