import { Bird, Rabbit, Turtle, Squirrel, Dog } from "lucide-react";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useSession } from "next-auth/react";

export function ChatSettings() {
  const [selectedTeacher, setSelectedTeacher] = useState<string>("");
  const [selectedEnergy, setSelectedEnergy] = useState<string>("");
  const [learningJourney, setLearningJourney] = useState<string>("");
  const { data: session } = useSession();

  console.log('logined in user data =>', session)
  const handleSubmit = async (event : any) => {
    event.preventDefault(); // Prevents default form submission behavior

    // Prepare data for the API call
    const formData = {
      teacher: selectedTeacher,
      energyLevel: selectedEnergy,
      learningJourney,
    };

    try {
      const response = await fetch("apiEndpoint", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Form submitted successfully:", result);

        // Refresh page
      } else {
        console.error("Form submission failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  return (
    <div className="w-full">
      <main className="flex flex-col gap-4 overflow-auto p-4">
        <div className="relative flex flex-col items-start gap-8 w-full" x-chunk="dashboard-03-chunk-0">
          <form className="grid w-full items-start gap-6" onSubmit={handleSubmit}>
            <fieldset className="grid gap-6 rounded-lg border p-4 w-full">
              <legend className="-ml-1 px-2 py-1 text-sm font-medium text-purple-600 bg-purple-100 rounded-lg shadow-inner">
                Your teacher
              </legend>

              {/* Teacher Image and Selection */}
              <div className="grid gap-3">
                <Label htmlFor="model">Who&apos;s your teacher today?</Label>
                <Select onValueChange={(value) => setSelectedTeacher(value)}>
                  <SelectTrigger id="model" className="flex items-center gap-3">
                    {selectedTeacher === '' && (
                      <div className="flex items-center gap-3 text-muted-foreground">
                        <img
                          src="https://img.freepik.com/free-photo/3d-rendering-emotions_23-2149081943.jpg"
                          alt="Bill Nye"
                          className="w-9 h-9 rounded-full border-2 border-blue-500"
                        />
                        <div className="grid gap-0.5">
                          <p>
                            Prof. Nova{" "}
                          </p>
                        </div>
                      </div>
                    )}
                    <SelectValue placeholder="Select your teacher" />
                  </SelectTrigger>

                  <SelectContent>
                    {/* Teacher options */}
                    <SelectItem value="genesis">
                      <div className="flex items-center gap-3">
                        <img
                          src="https://img.freepik.com/free-photo/3d-rendering-emotions_23-2149081943.jpg"
                          alt="Prof. Nova"
                          className="w-9 h-9 rounded-full border-2 border-blue-500 hover:scale-150"
                        />
                        <div className="grid gap-0.5">
                          <p>
                            Prof. Nova{" "}
                            <span className="font-medium text-foreground">
                              Science Genesis
                            </span>
                          </p>
                        </div>
                      </div>
                    </SelectItem>

                    <SelectItem value="explorer">
                      <div className="flex items-center gap-3">
                        <img
                          src="https://us.123rf.com/450wm/seby87/seby871903/seby87190303512/120460749-cute-yellow-emoticon-for-web-vector-illustration.jpg?ver=6"
                          alt="Dr. Algorith"
                          className="w-9 h-9 rounded-full border-2 border-green-500 hover:scale-150"
                        />
                        <div className="grid gap-0.5">
                          <p>
                            Dr. Algorith{" "}
                            <span className="font-medium text-foreground">
                              The Math Adventurer
                            </span>
                          </p>
                        </div>
                      </div>
                    </SelectItem>

                    <SelectItem value="quantum">
                      <div className="flex items-center gap-3">
                        <img
                          src="https://img.freepik.com/free-photo/3d-rendering-emotions_23-2149081926.jpg"
                          alt="Sage Wit"
                          className="w-9 h-9 rounded-full border-2 border-purple-500 hover:scale-150"
                        />
                        <div className="grid gap-0.5">
                          <p>
                            Sage Wit{" "}
                            <span className="font-medium text-foreground">
                              Your Favorite Uncle
                            </span>
                          </p>
                        </div>
                      </div>
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>

              {/* Teacher's Energy Level */}
              {/* <div className="grid gap-3">
                <Label htmlFor="energy-level">⚡ How energetic is your teacher?</Label>
                <Select onValueChange={(value) => setSelectedEnergy(value)}>
                  <SelectTrigger
                    id="energy-level"
                    className="flex items-start gap-3"
                  >
                    {selectedEnergy === '' && (
                      <div className="flex items-start gap-3">
                        <Bird className="w-5 h-5 text-yellow-500" />
                        <div className="grid gap-0.5">
                          <p>
                            Balanced Bird{" "}
                          </p>
                        </div>
                      </div>
                    )}
                    <SelectValue placeholder="Pick the energy level!" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="rabbit">
                      <div className="flex items-start gap-3">
                        <Squirrel className="w-5 h-5 text-pink-500" />
                        <div className="grid gap-0.5">
                          <p>
                            Hyper Hare{" "}
                            <span className="font-medium text-foreground">
                              (Non-stop Action)
                            </span>
                          </p>
                          {selectedEnergy !== "rabbit" && (
                            <p className="text-xs" data-description>
                              Blink and you might miss it—rapid-fire lessons ahead!
                            </p>
                          )}
                        </div>
                      </div>
                    </SelectItem>


                    <SelectItem value="bird">
                      <div className="flex items-start gap-3">
                        <Bird className="w-5 h-5 text-yellow-500" />
                        <div className="grid gap-0.5">
                          <p>
                            Balanced Bird{" "}
                            <span className="font-medium text-foreground">(Smooth Sailing)</span>
                          </p>
                          {selectedEnergy !== "bird" && (
                            <p className="text-xs" data-description>
                              Cruising through the topics with balance and clarity.
                            </p>
                          )}
                        </div>
                      </div>
                    </SelectItem>

                    <SelectItem value="turtle">
                      <div className="flex items-start gap-3">
                        <Turtle className="w-5 h-5 text-blue-500" />
                        <div className="grid gap-0.5">
                          <p>
                            Thoughtful Turtle{" "}
                            <span className="font-medium text-foreground">(Slow and Steady)</span>
                          </p>
                          {selectedEnergy !== "turtle" && (
                            <p className="text-xs" data-description>
                              Taking it slow so nothing is missed.
                            </p>
                          )}
                        </div>
                      </div>
                    </SelectItem>

                    <SelectItem value="owl">
                      <div className="flex items-start gap-3">
                        <Dog className="w-5 h-5 text-purple-500" />
                        <div className="grid gap-0.5">
                          <p>
                            Wise Owl{" "}
                            <span className="font-medium text-foreground">(Deep Thinker)</span>
                          </p>
                          {selectedEnergy !== "owl" && (
                            <p className="text-xs" data-description>
                              Pauses to drop wisdom bombs every few minutes.
                            </p>
                          )}
                        </div>
                      </div>
                    </SelectItem>

                    <SelectItem value="cheetah">
                      <div className="flex items-start gap-3">
                        <Rabbit className="w-5 h-5 text-red-500" />
                        <div className="grid gap-0.5">
                          <p>
                            Cheetah Sprint{" "}
                            <span className="font-medium text-foreground">(Lightning Quick)</span>
                          </p>
                          {selectedEnergy !== "cheetah" && (
                            <p className="text-xs" data-description>
                              Speeding through at breakneck pace—hold on tight!
                            </p>
                          )}
                        </div>
                      </div>
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div> */}
              
              {/* Text Area */}
              <div className="grid gap-3">
                <Label htmlFor="content">
                  📖 Tell us about your learning journey!
                </Label>
                <Textarea
                  id="content"
                  placeholder="Tell us about yourself! Do you have any learning challenges like dyslexia? What's your learning style—visual, auditory, or kinesthetic? How are you feeling today, and how’s your experience with education and life in general?"
                  className="min-h-[9.5rem] rounded-md border-dashed border-2"
                  value={learningJourney}
                  onChange={(e) => setLearningJourney(e.target.value)}
                />
              </div>
            </fieldset>

            {/* Playful Update Button */}
            <Button
              type="submit"
              className="w-full bg-purple-600 text-white font-bold p-3 rounded-lg shadow-lg hover:bg-purple-700 transition duration-300 ease-in-out transform hover:scale-105"
            >
              🚀 Update Me
            </Button>
          </form>
        </div>
      </main>
    </div>
  );
}
