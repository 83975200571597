'use client'

import type { UIState } from "@/app/actions"
import React from "react" 

interface ChatMessagesProps {
  messages: UIState
}

type GroupedMessage = {
  id: string
  components: React.ReactNode[]
}

export function ChatMessage({ messages } : ChatMessagesProps) {
  console.log('reduce =>', messages.length, messages)
  if (!messages.length) {
    console.log('reduce return =>', messages.length)

    return null
  }

  const groupedMessages = messages.reduce(
    (acc: { [key: string]: GroupedMessage }, message) => {
      if (!acc[message.id]) {
        acc[message.id] = {
          id: message.id,
          components: []
        }
      }
      acc[message.id].components.push(message.component)
      return acc
    },
    {}
  );

  const groupedMessagesArray = Object.values(groupedMessages).map(group => ({
    ...group,
    components: group.components as React.ReactNode[]
  })) as {
    id: string
    components: React.ReactNode[]
  }[]

  // console.log('groupedMessagesArray =>', groupedMessagesArray)

  return (
    <>
      {groupedMessagesArray.map((groupedMessage: GroupedMessage) => (
        <div key={groupedMessage.id}>
          {groupedMessage.components.map((component, i) => (
            <div key={`${groupedMessage.id}-${i}`}>{component}</div>
          ))}
        </div>
      ))}
    </>
  )
  
}